import { get } from 'lodash';
import { CmsModel } from 'client/data/models/cms';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { parseContent } from 'client/data/cms/content';
import { getMeta } from 'site-modules/shared/utils/seo-helper';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getBaseBreadcrumb, getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { EdmundsCpaNoAdhesionDecorator } from 'site-modules/shared/pages/decorators/edmunds-cpa-no-adhesion';
/* careers_v2 chal */
import { Careers, CAREERS_FILE_PATH } from './v2/careers';

export const pageOptions = {
  name: 'other_about_us_jobs_index',
  category: 'other_about_us_jobs_index',
  chunkName: 'careers-v2',
  preload: store => {
    const preloader = new ModelPreloader(store);

    preloader.resolve(`content["${CAREERS_FILE_PATH}"]`, CmsModel);

    return preloader.load();
  },
  seo: (state, props) => {
    const modelState = getModelState(state);
    const pathname = get(props, 'location.pathname');
    const content = modelState.get(`content["${CAREERS_FILE_PATH}"]`, CmsModel);
    const parsedContent = parseContent(content);
    const title = get(content, 'title', '');

    return {
      ...getMeta(parsedContent),
      jsonld: [getBreadcrumbSchema([getHomeBreadcrumb(), getBaseBreadcrumb(title, pathname)])],
    };
  },
  component: Careers,
  decorator: EdmundsCpaNoAdhesionDecorator,
};

export default pageDefinition(pageOptions);
