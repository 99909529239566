import React, { useState, useEffect } from 'react';
import { IMAGE_SIZES } from 'client/site-modules/shared/constants/editorial/editorial';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import { getFullImageUrl, getStaticImageUrl } from 'client/utils/image-helpers';
import { MEDIA_PATHS } from './mediaPaths';

const slides = [
  getStaticImageUrl(MEDIA_PATHS.sliderImageOneUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageTwoUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageThreeUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageFourUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageFiveUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageSixUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageSevenUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageEightUrl),
  getStaticImageUrl(MEDIA_PATHS.sliderImageNineUrl),
];

export const CareersImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [set, clear] = useTimeout();
  useEffect(() => {
    set(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      setCurrentIndex(nextIndex);
    }, 7000);

    return () => {
      clear();
    };
  }, [clear, currentIndex, set]);

  return (
    <div className="image-slider">
      <img
        src={getFullImageUrl(slides[currentIndex], IMAGE_SIZES.xs)}
        alt={`${currentIndex + 1}`}
        className="img-fluid"
      />
    </div>
  );
};
